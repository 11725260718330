<template>
  <el-dialog
    :title="$t('complaint')"
    :visible.sync="$store.state.complaint.complaintDialogVisible"
    custom-class="complaintPopover"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    center
  >
    <!-- <div class="complaint-header">{{ $t('complaint')}}</div> -->
    <!-- 投诉正文 -->
    <!-- 帖子 -->
    <div v-if="postDetails">
      <!-- 视频类帖子 -->
      <div v-if="documentType == 1" class="complaint-wrap">
        <div class="article-img" style="position: relative">
          <img :src="pic" alt="" />
          <!-- <video :src="vodie" style="width:100%;height:100%" id="video"></video> -->
          <div class="videoStatus">
            <span
              class="iconfont_Me icon-play2-fill videoStatusIconStyle"
            ></span>
          </div>
        </div>
        <div class="article-content">
          <div
            class="article-name"
            :class="postDetails.vipStatus ? 'vipName' : ''"
            @click.stop="goPersonalCenter(postDetails.providerId)"
          >
            {{ postDetails.name }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('paidMember')"
              placement="bottom"
              popper-class="me-tooltip-effect"
            >
              <el-image
                style="width: 17px; height: 14px;margin-left: 3px;"
                v-if="postDetails.vipStatus"
                :src="require('@/assets/images/pay/masonry.png')"
                fit="fill"
                @click.stop="handleClickVip(postDetails.providerId)"
              >
              </el-image>
            </el-tooltip>
          </div>
          <div
            class="article-text"
            v-html="$emoReplaceToImage(hyperlinksCheck(content))"
          ></div>
        </div>
      </div>
      <!-- 图片帖子 -->
      <div v-if="documentType == 3" class="complaint-wrap">
        <div class="article-img">
          <img :src="pic" alt="" />
        </div>
        <div class="article-content">
          <div
            class="article-name"
            :class="postDetails.vipStatus ? 'vipName' : ''"
            @click.stop="goPersonalCenter(postDetails.providerId)"
          >
            {{ postDetails.name }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('paidMember')"
              placement="bottom"
              popper-class="me-tooltip-effect"
            >
              <el-image
                style="width: 17px; height: 14px;margin-left: 3px;"
                v-if="postDetails.vipStatus"
                :src="require('@/assets/images/pay/masonry.png')"
                fit="fill"
                @click.stop="handleClickVip(postDetails.providerId)"
              >
              </el-image>
            </el-tooltip>
          </div>
          <div
            class="article-text"
            v-html="$emoReplaceToImage(hyperlinksCheck(content))"
          ></div>
        </div>
      </div>
      <!-- 文件类型帖子 -->
      <div v-if="documentType == 4" class="complaint-wrap">
        <div class="article-img">
          <img :src="iconSrc()" alt="" />
        </div>
        <div
          class="article-content ellipsis-2"
          :class="fileInfo.vipStatus ? 'vipName' : ''"
        >
          {{ fileInfo.name }}
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('paidMember')"
            placement="bottom"
            popper-class="me-tooltip-effect"
          >
            <el-image
              style="width: 17px; height: 14px;margin-left: 3px;"
              v-if="postDetails.vipStatus"
              :src="require('@/assets/images/pay/masonry.png')"
              fit="fill"
              @click.stop="handleClickVip(postDetails.id)"
            >
            </el-image>
          </el-tooltip>
        </div>
      </div>
      <!-- 链接类型帖子 -->
      <div
        v-if="documentType == 0 && webInfos.length > 0"
        class="complaint-wrap"
      >
        <div class="article-img">
          <img :src="webInfos[0].content.image" alt="" />
        </div>
        <div class="article-content ellipsis-2">
          <div
            class="article-name"
            :class="webInfos[0].vipStatus ? 'vipName' : ''"
          >
            {{ webInfos[0].content.title }}
          </div>
          <div
            class="article-text"
            v-html="
              $emoReplaceToImage(hyperlinksCheck(webInfos[0].content.content))
            "
          ></div>
        </div>
      </div>
      <!-- 纯文字类型帖子 -->
      <div
        v-if="documentType == 0 && webInfos.length == 0"
        class="complaint-wrap"
      >
        <div class="article-content ellipsis-2">
          <div
            class="article-name"
            :class="postDetails.vipStatus ? 'vipName' : ''"
            @click.stop="goPersonalCenter(postDetails.providerId)"
          >
            {{ postDetails.name }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('paidMember')"
              placement="bottom"
              popper-class="me-tooltip-effect"
            >
              <el-image
                style="width: 17px; height: 14px;margin-left: 3px;"
                v-if="postDetails.vipStatus"
                :src="require('@/assets/images/pay/masonry.png')"
                fit="fill"
                @click.stop="handleClickVip(postDetails.providerId)"
              >
              </el-image>
            </el-tooltip>
          </div>
          <div
            class="article-text"
            v-html="$emoReplaceToImage(hyperlinksCheck(content))"
          ></div>
        </div>
      </div>
    </div>
    <!-- 用户 -->
    <div v-if="type == 3" class="complaint-wrap">
      <div class="article-img" style="border-radius: 50%; overflow: hidden">
        <img :src="personalInfo.profilePhoto" alt="" />
        <!-- <img :src="countryPic" alt=""> -->
      </div>
      <div class="article-content ellipsis-2">
        <div
          class="article-name"
          :class="personalInfo.vipStatus ? 'vipName' : ''"
          @click.stop="goPersonalCenter(personalInfo.id)"
        >
          {{ personalInfo.name }}
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('paidMember')"
            placement="bottom"
            popper-class="me-tooltip-effect"
          >
            <el-image
              style="width: 17px; height: 14px;margin-left: 3px;"
              v-if="personalInfo.vipStatus"
              :src="require('@/assets/images/pay/masonry.png')"
              fit="fill"
              @click.stop="handleClickVip(personalInfo.id)"
            >
            </el-image>
          </el-tooltip>
        </div>
        <div class="article-text">ID: {{ personalInfo.ucId }}</div>
      </div>
    </div>
    <!-- 群 -->
    <div v-if="type == 4" class="complaint-wrap">
      <div class="article-img">
        <img
          :src="
            groupInfo.group_header
              ? groupInfo.group_header
              : groupInfo.groupHeader
          "
          alt=""
        />
      </div>
      <div class="article-content ellipsis-2">
        <div class="article-name">
          {{
            groupInfo.group_name ? groupInfo.group_name : groupInfo.groupName
          }}
        </div>
        <div class="article-text" v-html="groupInfo.groupInfo"></div>
      </div>
    </div>
    <!-- 帖子评论 -->
    <div v-if="type == 11 || type == 31 || type == 41" class="complaint-wrap">
      <!-- <div class="article-img">
        <img :src="comment.userAvatar" alt="" />
      </div> -->
      <div class="article-content ellipsis-2">
        <div class="article-name">{{ comment.reviewer }}</div>
        <div
          class="article-text"
          v-html="$emoReplaceToImage(hyperlinksCheck(commentContent))"
        ></div>
        <div
          v-if="commentImage && commentImage != ''"
          :style="{ width: '100%', overflow: 'hidden', 'margin-top': '10px' }"
        >
          <el-image
            :src="commentImage"
            fit="cover"
            style="width: 100px; height: 100%"
          ></el-image>
        </div>
      </div>
    </div>

    <!-- 投诉原因 -->
    <div class="complaint-reason">
      <div class="reason-title">
        {{ $t("complaintReason") }}({{ $t("required") }})
      </div>
      <div class="reason-list">
        <el-radio
          v-model="radio"
          :label="item.itemId"
          v-for="item in reasonList"
          :key="item.itemId"
          >{{ item.itemVal }}
        </el-radio>
        <el-input
          v-if="radio == -1"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 10 }"
          :placeholder="$t('pleaseEnterContent')"
          v-model="textarea"
          resize="none"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </div>
      <el-button
        class="complaint-btn"
        icon="el-icon-check"
        :disabled="disabled"
        :loading="loading"
        @click="submit"
      >
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
let baseUrl = "https://pic.melinked.com";
import { getPostInformation } from "@/api/newVersion/postContentDetails";
export default {
  data() {
    return {
      type: null,
      userInfo: null,
      groupInfo: null,
      radio: null,
      textarea: "",
      postDetails: null,
      content: null,
      type: null,
      comment: null,
      loading: false,
    };
  },
  props: {
    reasonList: {
      type: Array,
      default: [],
    },
  },
  watch: {
    "$store.state.complaint.complaintId": {
      handler(val, oldVal) {
        if (val == oldVal) {
        } else {
          this.getDetails(val);
        }
      },
      immediate: true,
    },
  },
  computed: {
    disabled: function() {
      if (!this.radio || (this.radio == -1 && !this.textarea)) {
        return true;
      } else {
        return false;
      }
    },
    commentContent() {
      return this.reconvert(this.comment.content);
    },
    commentImage() {
      return this.reconvert(this.comment.imgUrl);
    },
  },
  mounted() {},
  methods: {
    goPersonalCenter(id) {
      if (id) {
        this.routeJump({
          name: "personalCenter",
          params: { id: id },
        });
      }
    },
    clearData() {
      this.postDetails = null;
      this.personalInfo = null;
      this.groupInfo = null;
      this.comment = null;
    },
    async getDetails(val) {
      this.clearData();
      let type = this.$store.state.complaint.complaintType;
      this.type = type;
      // 违规数据类型 1：帖子 3:用户 4：群 31用户评论 11：帖子评论 41：群评论
      if (type == 1) {
        let result = await getPostInformation(val);
        this.postDetails = result.data.data;
        this.documentType = this.postDetails.documentType;
        this.details =
          this.postDetails.detail && JSON.parse(this.postDetails.detail);
        this.content = (this.details[0] && this.details[0].content) || "";
        // 图片与文件类型的帖子
        if (this.documentType == 3 || this.documentType == 4) {
          this.pic = this.postDetails.attachments[0].path;
          this.fileInfo = this.postDetails.attachments[0];
        }
        // 链接类型的帖子
        if (this.documentType == 0) {
          this.webInfos = this.details.filter((item) => item.key == "web");
        }
        // 视频类型的帖子
        if (this.documentType == 1) {
          this.pic =
            this.postDetails.attachments[0].path + "?vframe/jpg/offset/0";
          this.vodie = this.postDetails.attachments[0].path;
        }
      }
      if (type == 3) {
        this.personalInfo = this.$store.state.complaint.personalInfo;
        if (this.personalInfo.profilePhoto) {
          let reg = /(http|https):\/\/\S*/;
          let isA = reg.test(this.personalInfo.profilePhoto);
          if (!isA) {
            this.personalInfo.profilePhoto =
              baseUrl + "/" + this.personalInfo.profilePhoto;
          }
        }
        if (this.personalInfo.country) {
          this.countryPic = `../../../assets/images/newCountrys/country_flag_${this.personalInfo.country}.png`;
        }
      }
      if (type == 4) {
        this.groupInfo = this.$store.state.complaint.groupInfo;
        let reg = /(http|https):\/\/\S*/;
        let isA = reg.test(this.groupInfo.groupHeader);
        if (!isA) {
          this.groupInfo.groupHeader =
            baseUrl + "/" + this.groupInfo.groupHeader;
        }
      }
      if (type == 11 || type == 31 || type == 41) {
        this.comment = this.$store.state.complaint.comment;
      }
    },
    //取消操作
    dialogVisible() {
      this.$store.commit("setComplaintDialogVisible", false);
      this.clearDate();
    },
    beforeClose() {
      this.dialogVisible();
    },
    submit() {
      this.loading = true;
      this.$emit(
        "confirmComplaint",
        {
          textarea: this.textarea,
          value: this.radio,
        },
        () => {
          this.clearDate();
        }
      );
    },
    clearDate() {
      this.loading = false;
      this.radio = "";
      this.textarea = "";
    },
    iconSrc() {
      if (this.judgment(this.fileInfo.name, "txt")) {
        return require("../../../assets/images/newPublish/text.png");
      } else if (
        this.judgment(this.fileInfo.name, "doc") ||
        this.judgment(this.fileInfo.name, "docx")
      ) {
        return require("../../../assets/images/newPublish/word.png");
      } else if (this.judgment(this.fileInfo.name, "pdf")) {
        return require("../../../assets/images/newPublish/pdf.png");
      } else if (
        this.judgment(this.fileInfo.name, "xlsx") ||
        this.judgment(this.fileInfo.name, "xls")
      ) {
        return require("../../../assets/images/newPublish/excel.png");
      } else if (
        this.judgment(this.fileInfo.name, "pptx") ||
        this.judgment(this.fileInfo.name, "ppt")
      ) {
        return require("../../../assets/images/newPublish/ppt.png");
      } else if (
        this.judgment(this.fileInfo.name, "tar") ||
        this.judgment(this.fileInfo.name, "7z") ||
        this.judgment(this.fileInfo.name, "bz2") ||
        this.judgment(this.fileInfo.name, "gz") ||
        this.judgment(this.fileInfo.name, "wim") ||
        this.judgment(this.fileInfo.name, "xz") ||
        this.judgment(this.fileInfo.name, "zip")
      ) {
        return require("../../../assets/images/newPublish/compressed.png");
      } else {
        return require("../../../assets/images/newPublish/unknown.png");
      }
    },
    // 判断是否是这个文件
    judgment(text, type) {
      const handlerText = text.toLowerCase();
      let typeLength = handlerText.length - handlerText.lastIndexOf(type);
      return handlerText.lastIndexOf(type) != -1 && typeLength == type.length;
    },
    // 将Unicode转化为评论
    reconvert(str) {
      str = str.replace(/(\\u)(\w{1,4})/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#x)(\w{1,4});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#)(\d{1,6});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2"))
        );
      });
      return str;
    },
    play() {
      let ad = document.getElementById("video");
      ad.play();
    },
  },
};
</script>

<style lang="scss">
.ellipsis-2 {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2;
}

.complaintPopover {
  width: 600px !important;
  height: auto !important;
  padding: 0 40px 30px 40px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /*修改滚动条样式*/
  div::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    display: none;
  }

  // max-height: 60%;
  user-select: none;

  .complaint-header {
    font-size: 16px;
    font-weight: 700;
    color: #373737;
    text-align: center;
    padding: 10px 30px;
  }

  .complaint-wrap {
    padding: 14px;
    background: #f4f5f7;
    border-radius: 8px;
    display: flex;

    .videoStatusIconStyle {
      position: absolute;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 18px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .article-img {
      width: 80px;
      height: 80px;
      margin-right: 18px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .article-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .article-name {
        font-weight: 700;
        color: #333333;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .article-text {
        width: 100%;
        color: #373737;
        font-size: 12px;
        line-height: 24px;
        max-height: 96px;
        letter-spacing: 0;
        white-space: pre-wrap;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  .complaint-reason {
    flex: 1;
    margin-top: 34px;
    display: flex;
    flex-direction: column;

    .reason-title {
      font-size: 16px;
      font-weight: 700;
      color: #1a051d;
    }

    .reason-list {
      margin: 10px 0 30px 0;
      // height: 224px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      overflow: auto;

      .el-radio {
        font-size: 14px;
        color: #333333;
        margin-right: 0;
        line-height: 4;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-direction: row-reverse;
        border-bottom: 1px solid #f6f6f6;

        &:last-child {
          border-bottom: none;
        }

        .el-radio__label {
          padding-left: 0;
        }

        .el-radio__inner {
          width: 18px;
          height: 18px;

          &::after {
            color: #fff;
            width: 0 !important;
            height: 0 !important;
            top: -120% !important;
            left: 8% !important;
            // background: red;
            font-family: "element-icons" !important;
            content: "\E6DA";
          }
        }
      }

      .el-radio__input {
        line-height: 4;
      }

      .el-textarea {
        // padding-left: 4px;
        height: 142px;
        border: none;

        .el-textarea__inner {
          height: 142px !important;
          border: none;
          background: #f7f7f7;
        }

        .el-input__count {
          background: #f7f7f7;
        }
      }
    }

    .complaint-btn {
      width: 150px;
      height: 40px;
      background: #33cc66;
      border-radius: 4px;
      color: #fff;
      align-self: center;
      margin-top: auto;
      font-size: 16px;

      &.el-button.is-disabled {
        background: #cccccc;
        color: #fff;
      }
    }
  }

  .el-dialog__header {
    margin-bottom: 30px;
  }

  .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
    color: #cccccc;
  }

  .el-dialog--center,
  .el-dialog__body {
    padding: 0 !important;
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
