var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("complaint"),
        visible: _vm.$store.state.complaint.complaintDialogVisible,
        "custom-class": "complaintPopover",
        "before-close": _vm.beforeClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.complaint,
            "complaintDialogVisible",
            $event
          )
        }
      }
    },
    [
      _vm.postDetails
        ? _c("div", [
            _vm.documentType == 1
              ? _c("div", { staticClass: "complaint-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "article-img",
                      staticStyle: { position: "relative" }
                    },
                    [
                      _c("img", { attrs: { src: _vm.pic, alt: "" } }),
                      _c("div", { staticClass: "videoStatus" }, [
                        _c("span", {
                          staticClass:
                            "iconfont_Me icon-play2-fill videoStatusIconStyle"
                        })
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "article-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "article-name",
                        class: _vm.postDetails.vipStatus ? "vipName" : "",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goPersonalCenter(
                              _vm.postDetails.providerId
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.postDetails.name) +
                            "\n          "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("paidMember"),
                              placement: "bottom",
                              "popper-class": "me-tooltip-effect"
                            }
                          },
                          [
                            _vm.postDetails.vipStatus
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "17px",
                                    height: "14px",
                                    "margin-left": "3px"
                                  },
                                  attrs: {
                                    src: require("@/assets/images/pay/masonry.png"),
                                    fit: "fill"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleClickVip(
                                        _vm.postDetails.providerId
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "article-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$emoReplaceToImage(
                            _vm.hyperlinksCheck(_vm.content)
                          )
                        )
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _vm.documentType == 3
              ? _c("div", { staticClass: "complaint-wrap" }, [
                  _c("div", { staticClass: "article-img" }, [
                    _c("img", { attrs: { src: _vm.pic, alt: "" } })
                  ]),
                  _c("div", { staticClass: "article-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "article-name",
                        class: _vm.postDetails.vipStatus ? "vipName" : "",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goPersonalCenter(
                              _vm.postDetails.providerId
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.postDetails.name) +
                            "\n          "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("paidMember"),
                              placement: "bottom",
                              "popper-class": "me-tooltip-effect"
                            }
                          },
                          [
                            _vm.postDetails.vipStatus
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "17px",
                                    height: "14px",
                                    "margin-left": "3px"
                                  },
                                  attrs: {
                                    src: require("@/assets/images/pay/masonry.png"),
                                    fit: "fill"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleClickVip(
                                        _vm.postDetails.providerId
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "article-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$emoReplaceToImage(
                            _vm.hyperlinksCheck(_vm.content)
                          )
                        )
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _vm.documentType == 4
              ? _c("div", { staticClass: "complaint-wrap" }, [
                  _c("div", { staticClass: "article-img" }, [
                    _c("img", { attrs: { src: _vm.iconSrc(), alt: "" } })
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "article-content ellipsis-2",
                      class: _vm.fileInfo.vipStatus ? "vipName" : ""
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.fileInfo.name) + "\n        "
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("paidMember"),
                            placement: "bottom",
                            "popper-class": "me-tooltip-effect"
                          }
                        },
                        [
                          _vm.postDetails.vipStatus
                            ? _c("el-image", {
                                staticStyle: {
                                  width: "17px",
                                  height: "14px",
                                  "margin-left": "3px"
                                },
                                attrs: {
                                  src: require("@/assets/images/pay/masonry.png"),
                                  fit: "fill"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleClickVip(
                                      _vm.postDetails.id
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.documentType == 0 && _vm.webInfos.length > 0
              ? _c("div", { staticClass: "complaint-wrap" }, [
                  _c("div", { staticClass: "article-img" }, [
                    _c("img", {
                      attrs: { src: _vm.webInfos[0].content.image, alt: "" }
                    })
                  ]),
                  _c("div", { staticClass: "article-content ellipsis-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "article-name",
                        class: _vm.webInfos[0].vipStatus ? "vipName" : ""
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.webInfos[0].content.title) +
                            "\n        "
                        )
                      ]
                    ),
                    _c("div", {
                      staticClass: "article-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$emoReplaceToImage(
                            _vm.hyperlinksCheck(_vm.webInfos[0].content.content)
                          )
                        )
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _vm.documentType == 0 && _vm.webInfos.length == 0
              ? _c("div", { staticClass: "complaint-wrap" }, [
                  _c("div", { staticClass: "article-content ellipsis-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "article-name",
                        class: _vm.postDetails.vipStatus ? "vipName" : "",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goPersonalCenter(
                              _vm.postDetails.providerId
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.postDetails.name) +
                            "\n          "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("paidMember"),
                              placement: "bottom",
                              "popper-class": "me-tooltip-effect"
                            }
                          },
                          [
                            _vm.postDetails.vipStatus
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "17px",
                                    height: "14px",
                                    "margin-left": "3px"
                                  },
                                  attrs: {
                                    src: require("@/assets/images/pay/masonry.png"),
                                    fit: "fill"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleClickVip(
                                        _vm.postDetails.providerId
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "article-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$emoReplaceToImage(
                            _vm.hyperlinksCheck(_vm.content)
                          )
                        )
                      }
                    })
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.type == 3
        ? _c("div", { staticClass: "complaint-wrap" }, [
            _c(
              "div",
              {
                staticClass: "article-img",
                staticStyle: { "border-radius": "50%", overflow: "hidden" }
              },
              [
                _c("img", {
                  attrs: { src: _vm.personalInfo.profilePhoto, alt: "" }
                })
              ]
            ),
            _c("div", { staticClass: "article-content ellipsis-2" }, [
              _c(
                "div",
                {
                  staticClass: "article-name",
                  class: _vm.personalInfo.vipStatus ? "vipName" : "",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.goPersonalCenter(_vm.personalInfo.id)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.personalInfo.name) + "\n        "
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t("paidMember"),
                        placement: "bottom",
                        "popper-class": "me-tooltip-effect"
                      }
                    },
                    [
                      _vm.personalInfo.vipStatus
                        ? _c("el-image", {
                            staticStyle: {
                              width: "17px",
                              height: "14px",
                              "margin-left": "3px"
                            },
                            attrs: {
                              src: require("@/assets/images/pay/masonry.png"),
                              fit: "fill"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleClickVip(_vm.personalInfo.id)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "article-text" }, [
                _vm._v("ID: " + _vm._s(_vm.personalInfo.ucId))
              ])
            ])
          ])
        : _vm._e(),
      _vm.type == 4
        ? _c("div", { staticClass: "complaint-wrap" }, [
            _c("div", { staticClass: "article-img" }, [
              _c("img", {
                attrs: {
                  src: _vm.groupInfo.group_header
                    ? _vm.groupInfo.group_header
                    : _vm.groupInfo.groupHeader,
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "article-content ellipsis-2" }, [
              _c("div", { staticClass: "article-name" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.groupInfo.group_name
                        ? _vm.groupInfo.group_name
                        : _vm.groupInfo.groupName
                    ) +
                    "\n      "
                )
              ]),
              _c("div", {
                staticClass: "article-text",
                domProps: { innerHTML: _vm._s(_vm.groupInfo.groupInfo) }
              })
            ])
          ])
        : _vm._e(),
      _vm.type == 11 || _vm.type == 31 || _vm.type == 41
        ? _c("div", { staticClass: "complaint-wrap" }, [
            _c("div", { staticClass: "article-content ellipsis-2" }, [
              _c("div", { staticClass: "article-name" }, [
                _vm._v(_vm._s(_vm.comment.reviewer))
              ]),
              _c("div", {
                staticClass: "article-text",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$emoReplaceToImage(
                      _vm.hyperlinksCheck(_vm.commentContent)
                    )
                  )
                }
              }),
              _vm.commentImage && _vm.commentImage != ""
                ? _c(
                    "div",
                    {
                      style: {
                        width: "100%",
                        overflow: "hidden",
                        "margin-top": "10px"
                      }
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100%" },
                        attrs: { src: _vm.commentImage, fit: "cover" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "complaint-reason" },
        [
          _c("div", { staticClass: "reason-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("complaintReason")) +
                "(" +
                _vm._s(_vm.$t("required")) +
                ")\n    "
            )
          ]),
          _c(
            "div",
            { staticClass: "reason-list" },
            [
              _vm._l(_vm.reasonList, function(item) {
                return _c(
                  "el-radio",
                  {
                    key: item.itemId,
                    attrs: { label: item.itemId },
                    model: {
                      value: _vm.radio,
                      callback: function($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio"
                    }
                  },
                  [_vm._v(_vm._s(item.itemVal) + "\n      ")]
                )
              }),
              _vm.radio == -1
                ? _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 10 },
                      placeholder: _vm.$t("pleaseEnterContent"),
                      resize: "none",
                      maxlength: "200",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea"
                    }
                  })
                : _vm._e()
            ],
            2
          ),
          _c("el-button", {
            staticClass: "complaint-btn",
            attrs: {
              icon: "el-icon-check",
              disabled: _vm.disabled,
              loading: _vm.loading
            },
            on: { click: _vm.submit }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }